import {Component, EventEmitter, Input, model, ModelSignal, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {DialogComponent} from 'src/app/components/utilities/dialog/dialog.component';
import {Color} from 'src/app/enums/core/color.enum';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {GAP_BUTTONS, GUTTER_FORM} from 'src/app/helpers/core/site-config.helper';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';

const RAD_STANDALONE_COMPONENTS = [
    DialogComponent
];

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-confirm-dialog',
    standalone: true,
    imports: [
        TranslateModule,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES
    ],
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
    @Input() public width: string = '26.25rem';
    @Input() public maxWidth?: string;
    @Input() public fullWidth: boolean = false;
    @Input() public fullHeight: boolean = false;
    @Input() public header?: string | null;
    @Input() public centerHeader: boolean = false;
    @Input() public headerIcon?: GoogleIcon;
    @Input() public headerIconColor?: Color;
    @Input() public headerIconNewRow: boolean = false;
    @Input() public blur: boolean = false;
    @Input() public closable = true;

    @Input() public bodyContent?: string | null;
    @Input() public htmlContent?: boolean;
    @Input() public confirmButtonLabel: string = 'yes';
    @Input() public confirmButtonSeverity: Severity = Severity.PRIMARY;
    @Input() public disableConfirmButton: boolean = false;
    @Input() public cancelButtonLabel: string = 'no';
    @Input() public cancelButtonSeverity: Severity = Severity.PRIMARY;
    @Input() public displayCancelButton: boolean = true;
    @Input() public noCloseOnConfirm?: boolean = false;

    @Output() public confirm = new EventEmitter<void>();
    @Output() public radCancel = new EventEmitter<void>();
    @Output() public xClose = new EventEmitter<void>();

    public visible: ModelSignal<any> = model(false);

    protected readonly GUTTER_FORM = GUTTER_FORM;
    protected readonly GAP_BUTTONS = GAP_BUTTONS;

    protected onConfirm() {
        if (!this.noCloseOnConfirm) this.close();
        this.confirm.emit();
    }

    protected onCancel() {
        this.close();
        this.radCancel.emit();
    }

    public open() {
        this.visible.set(true);
    }

    public close() {
        this.visible.set(false);
    }

    public onXClose() {
        this.xClose.emit();
        this.close();
    }
}