<app-dialog [(visible)]="visible"
            [width]="width"
            [maxWidth]="maxWidth"
            [fullWidth]="fullWidth"
            [fullHeight]="fullHeight"
            [header]="header"
            [centerHeader]="centerHeader"
            [headerIcon]="headerIcon"
            [headerIconColor]="headerIconColor"
            [headerIconNewRow]="headerIconNewRow"
            [blur]="blur"
            [closable]="closable"
            (xClose)="onXClose()">
    <div class="mb-6">
        <ng-content></ng-content>
        @if (bodyContent && !htmlContent) {
            <div>{{ bodyContent | translate }}</div>
        }

        @if (bodyContent && htmlContent) {
            <span [innerHTML]="bodyContent"></span>
        }
    </div>
    <div class="d-flex flex-md-row flex-column-reverse"
         [class]="[GAP_BUTTONS, 'justify-content-' + (displayCancelButton ? 'between' : 'end')].join(' ')">
        @if (displayCancelButton) {
            <p-button styleClass="w-below-md-100"
                      [label]="cancelButtonLabel | translate"
                      [outlined]="true"
                      [severity]="cancelButtonSeverity"
                      (onClick)="onCancel()"></p-button>
        }

        <p-button styleClass="w-below-md-100"
                  [label]="confirmButtonLabel | translate"
                  [severity]="confirmButtonSeverity"
                  [disabled]="disableConfirmButton"
                  (onClick)="onConfirm()"></p-button>
    </div>
</app-dialog>